import React, { useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";

import { MoveActionContainer, ActionTitle, ActionIcon, MoveLabel } from "./styled";
import { StyleGuide } from "../../../../constants/StyleGuide";

type Props = {
    title: string;
    Icon: any;
    label?: string;
    onClick: () => Promise<void> | void;
    disabled?: boolean;
};

const MoveAction = ({ title, Icon, label = "Move", onClick, disabled }: Props) => {
    const [isLoading, setisLoading] = useState(false);
    const [isHovered, toggleHovered] = useState(false);
    return (
        <MoveActionContainer
            onMouseEnter={() => toggleHovered(true)}
            onMouseLeave={() => toggleHovered(false)}
            disabled={isLoading || disabled}
            onClick={async () => {
                setisLoading(true);
                await onClick();
                setisLoading(false);
            }}>
            <ActionTitle isHovered={isHovered}>
                <ActionIcon>
                    {isLoading ? (
                        <ClipLoader
                            color={StyleGuide.palette.secondaryBasil}
                            loading
                            size={10}
                        />
                    ) : (
                        <Icon isFocused={isHovered} />
                    )}
                </ActionIcon>
                {title}
            </ActionTitle>

            {isHovered && <MoveLabel>{label}</MoveLabel>}
        </MoveActionContainer>
    );
};

export default MoveAction;
