import { PlandayDropdown } from "../styled";
import TemplatesDropdown from "@components/Dropdowns/TemplatesDropdown";
import React from "react";
import { useAppSelector } from "../../../../../store/store";
import { selectSortedRestaurantOptions } from "../../../../../features/restaurants/reducer";

export default function RestaurantSelect({
    currentRestaurantId,
    restaurantId,
    setRestaurantId,
}: {
    currentRestaurantId: string;
    restaurantId: string | null;
    setRestaurantId: (id: string) => void;
}) {
    const restaurants = useAppSelector(selectSortedRestaurantOptions);
    const selectedDepartment = restaurants.find(d => d.id === restaurantId);

    const restaurantOptions = restaurants.filter(r => r.id !== currentRestaurantId);

    return (
        <PlandayDropdown>
            <TemplatesDropdown
                loading={false}
                placeholder="Select restaurant"
                selected={selectedDepartment}
                optionsList={restaurantOptions}
                getOptionLabel={option => option?.value || ""}
                getOptionKey={option => option?.id}
                getOptionSelected={option => option?.id === restaurantId}
                onOptionSelect={option => {
                    setRestaurantId(option.id);
                }}
                renderCustomOptions={() => {}}
            />
        </PlandayDropdown>
    );
}
