import mappet from "mappet";
import {
    ApplicationTypeValue,
    ApplicationTypes,
    TypeformWorkHours,
    mapWorkHours,
    mapVisaType,
    mapVisaSectionType,
    mapVisaWorkHours,
    mapVisaExpirationDate,
} from "@anna/shared";

export const getFormatedWorkHours = (target: TypeformWorkHours) => mapWorkHours(target);

const determineBOHPosition = (backOfHouse: ApplicationTypeValue) =>
    ApplicationTypes.boh.includes(backOfHouse);
const determineFOHPosition = (frontOfHouse: ApplicationTypeValue) =>
    ApplicationTypes.foh.includes(frontOfHouse);
const determineAdminPosition = (admin: ApplicationTypeValue) =>
    ApplicationTypes.administrative.includes(admin);
export const determineIsIntern = (value: ApplicationTypeValue) =>
    value?.toLowerCase()?.includes("intern") || null;

const convertToDate = createTime => createTime.toDate();

export const getAvailability = (target: {
    labels: string[];
    days: string[];
    shifts: string[];
}) => [...(target?.labels || []), ...(target?.days || []), ...(target?.shifts || [])];

/**
 *  Safe guard old applicants might have `label` defined instead of `labels`
 *  Mapping all to string[] or null
 */
const mapLanguages = (content: { labels?: string[]; label?: string }) => {
    if (content?.labels) return content?.labels;
    if (content?.label) return [content?.label];
    return null;
};
// Maps the application value to boolean or null if it is not specified
export function mapToBooleanOrNull(value: "Yes" | "No" | undefined) {
    if (!value) return null;
    return value === "Yes";
}
export function transformStartDate(startDate: Date | string) {
    return typeof startDate === "string" ? new Date(startDate) : startDate;
}

/**
 *  Safe guard old applicants might have `label` defined instead of `labels`
 *  Mapping all to string[] or null
 */
const mapPreferredLocations = (content: { labels?: string[]; label?: string }) => {
    if (content?.labels) return content?.labels;
    if (content?.label) return [content?.label];
    return null;
};

const schema = {
    id: "id",
    restaurantId: "restaurantId",
    restaurantTitle: "restaurantTitle",
    eventId: "eventId",
    eventType: "eventType",
    gender: "gender",
    createTime: { path: "createTime", modifier: convertToDate },
    cv: "answers.application_cv.content",
    email: "answers.application_email.content",
    firstName: "answers.application_firstname.content",
    lastName: "answers.application_lastname.content",
    motivation: "answers.application_motivation.content",
    phoneNumber: "answers.application_phone_number.content",
    position: "answers.application_position.content.label",
    hasCpr: {
        path: "answers.application_cpr.content.label",
        modifier: mapToBooleanOrNull,
    },
    positionDetail: {
        path: "answers",
        // not worth defining this type as we will remove this (soon)
        modifier: (answers: any) => {
            const positionDetail =
                answers?.application_position_detail?.content.label ||
                answers?.application_position_boh?.content.label ||
                answers?.application_position_foh?.content.label ||
                answers?.application_position_admin?.content.label;
            return positionDetail;
        },
    },
    positionBOH: "answers.application_position_boh.content.label",
    positionFOH: "answers.application_position_foh.content.label",
    positionAdmin: "answers.application_position_admin.content.label",
    isBackOfHouse: {
        path: "answers.application_position.content.label",
        modifier: determineBOHPosition,
    },
    isFrontOfHouse: {
        path: "answers.application_position.content.label",
        modifier: determineFOHPosition,
    },
    isAdministrative: {
        path: "answers.application_position.content.label",
        modifier: determineAdminPosition,
    },
    isIntern: {
        path: "answers.application_position.content.label",
        modifier: determineIsIntern,
    },
    internDuration: "answers.application_internship_duration.content.label",
    profilePicture: "answers.application_profile_picture.content",
    selfIntro: "answers.application_self_intro.content",
    sharePoolConsent: "answers.application_share_pool_consent.content",
    spokenLanguages: {
        path: "answers.application_spoken_languages.content",
        modifier: mapLanguages,
    },
    startDate: {
        path: "answers.application_start_date.content",
        modifier: transformStartDate,
    },
    termsConsent: "answers.application_terms_consent.content",
    visaType: {
        path: "answers.application_visa_type.content.label",
        modifier: mapVisaType,
    },
    preferredLocations: {
        path: "answers.application_preferred_locations.content",
        modifier: mapPreferredLocations,
    },
    availabilityYear: "answers.application_availability_year.content.label",
    visaSectionType: {
        path: "answers.application_visa_type.content.label",
        modifier: mapVisaSectionType,
    },
    visaWorkHours: {
        path: "answers.application_visa_type.content.label",
        modifier: mapVisaWorkHours,
    },
    visaWorkHoursNew: {
        path: "answers.application_visa_hours.content.label",
    },
    visaExpirationDate: {
        path: "answers.application_visa_type.content.label",
        modifier: mapVisaExpirationDate,
    },
    visaTypeHoliday: "answers.application_visa_type_holiday.content",
    workExperience: "answers.application_work_experience.content",
    workExperiences: "answers.application_work_experiences.content",
    workExperienceTotal: "answers.application_workexperience_total.content.label",
    intershipLength: "answers.application_internship_length.content",
    wineKnowledge: "answers.application_wine_knowledge.content",
    managementKnowledge: "answers.application_management_knowledge.content",
    shiftAvailability: {
        path: "answers.application_shift_availability.content",
        modifier: getAvailability,
    },
    drinksKnowledge: "answers.application_drinks_knowledge.content",
    coffeeKnowledge: "answers.application_coffee_knowledge.content",
    residenceCountry: "answers.application_residence_country.content",
    nationality: "answers.application_nationality.content",
    passportExpiration: "answers.application_passport_expiration.content",
    educationChef: "answers.application_education_chef.content.label",
    hospitalityDiploma: "answers.application_hospitality_diploma.content",
    internshipPeriodFirstPreference:
        "answers.application_internship_period_first_preference.content.label",
    internshipPeriodSecondPreference:
        "answers.application_internship_period_second_preference.content.label",
    birthdate: "answers.application_birthdate.content",
    address: "answers.application_address.content",
    applicantGender: "answers.application_gender.content.label",
    u18Drivercheck: "answers.application_u18_drivercheck.content.labels",
    currentLocation: "answers.application_location.content",
    workHours: {
        path: "answers.application_workhours.content.label",
        modifier: getFormatedWorkHours,
    },
    comments: "answers.application_comments.content",
    preferredLocations: "answers.application_preferred_locations.content",
};

export const applicationMapper = mappet(schema);
