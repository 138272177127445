import React, { useState, useEffect, useRef } from "react";
import { ReactComponent as CloseIcon } from "@assets/svg/close.svg";
import { Loader } from "@routes//Auth/components/Buttons/styled";
import MoveAction from "../MoveAction";
import {
    PlandayPopupContainer,
    CloseButton,
    PopupTitle,
    PlusIcon,
    PlandayFormContainer,
    PlandaySectionInfo,
    SendButtonText,
    CtaButton,
    PlandaySubmitButton,
    TransferIcon,
} from "../styled";
import { useAppSelector } from "../../../../../store/store";
import {
    selectApplicantIdByFunnelId,
    selectApplicants,
} from "../../../../../features/funnels/reducer";
import RestaurantSelect from "./RestaurantSelect";
import useIsApplicantTransferPending from "../../../../../features/transfer/hooks";
import { transferApplicantToAnotherRestaurant } from "../../../../../features/transfer/api";

const TransferPopup = () => {
    const { isPendingTransfer } = useIsApplicantTransferPending();
    const funnelId = useAppSelector(
        state => (state.funnels.focusedProfile || state.funnels.focusedChat) as string,
    );
    const applicantId = useAppSelector(selectApplicantIdByFunnelId) as string;
    const application = useAppSelector(
        state => state.applications.applicationMap[applicantId],
    );

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [restaurantId, setRestaurantId] = useState<string | null>(null);
    const [showPlandayPopup, togglePlandayPopup] = useState(false);

    const wrapperRef = useRef<HTMLDivElement>(null);

    const closePopup = () => {
        togglePlandayPopup(false);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                closePopup();
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef]);

    if (isPendingTransfer) {
        return (
            <MoveAction
                disabled={true}
                title="Transfer in progress"
                Icon={Loader}
                onClick={() => togglePlandayPopup(true)}
            />
        );
    }
    if (!showPlandayPopup) {
        return (
            <MoveAction
                title="Transfer applicant"
                Icon={TransferIcon}
                label=""
                onClick={() => togglePlandayPopup(true)}
            />
        );
    }

    const transferApplicant = async () => {
        try {
            if (!funnelId || !applicantId || !restaurantId) {
                throw new Error(
                    "Missing funnelId or applicantId or restaurantId to transfer applicant",
                );
            }
            setIsSubmitting(true);
            await transferApplicantToAnotherRestaurant({
                funnelId: funnelId,
                applicantId: applicantId,
                newRestaurantId: restaurantId,
                prevRestaurantId: application.restaurantId,
            });

            closePopup();
        } catch (error) {
            console.error(error);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <PlandayPopupContainer ref={wrapperRef}>
            <CloseButton onClick={closePopup}>
                <CloseIcon />
            </CloseButton>

            <PopupTitle>Transfer applicant</PopupTitle>

            <PlandayFormContainer>
                <PlandaySectionInfo>
                    Where would you like to transfer this applicant?
                </PlandaySectionInfo>
                <RestaurantSelect
                    currentRestaurantId={application.restaurantId}
                    restaurantId={restaurantId}
                    setRestaurantId={setRestaurantId}
                />
                <PlandaySubmitButton
                    isDisabled={isSubmitting || !restaurantId}
                    onClick={transferApplicant}>
                    {isSubmitting ? <Loader /> : "Transfer"}
                </PlandaySubmitButton>
            </PlandayFormContainer>
        </PlandayPopupContainer>
    );
};

export default TransferPopup;
