import { firestore } from "../../firebase/configure";
import { COLLECTIONS } from "../../constants/enums";
import { FunnelListView } from "@anna/shared";

export const getApplicants = ({
    restaurantIds,
    position,
    limit,
}: {
    restaurantIds: string[];
    position: string | null;
    limit: number;
}) => {
    if (position) {
        return (
            firestore
                .collection(COLLECTIONS.FUNNELS)
                .where("listView", "==", FunnelListView.ApplicantsList)
                .where("userMeta.position", "==", position)
                .where("restaurantId", "in", restaurantIds)
                .orderBy("updateTime", "desc")
                // .startAfter(lastDoc)
                .limit(limit)
        );
    }
    return (
        firestore
            .collection(COLLECTIONS.FUNNELS)
            .where("listView", "==", FunnelListView.ApplicantsList)
            .where("restaurantId", "in", restaurantIds)
            .orderBy("updateTime", "desc")
            // .startAfter(lastDoc)
            .limit(limit)
    );
};

export const getShortlist = ({
    restaurantIds,
    position,
    limit,
}: {
    restaurantIds: string[];
    position: string | null;
    limit: number;
}) => {
    if (position) {
        return (
            firestore
                .collection(COLLECTIONS.FUNNELS)
                .where("listView", "==", FunnelListView.ShortList)
                .where("userMeta.position", "==", position)
                .where("restaurantId", "in", restaurantIds)
                .orderBy("updateTime", "desc")
                // .startAfter(lastDoc)
                .limit(limit)
        );
    }
    return (
        firestore
            .collection(COLLECTIONS.FUNNELS)
            .where("listView", "==", FunnelListView.ShortList)
            .where("restaurantId", "in", restaurantIds)
            .orderBy("updateTime", "desc")
            // .startAfter(lastDoc)
            .limit(limit)
    );
};

export const getContactedlist = ({
    restaurantIds,
    position,
    limit,
}: {
    restaurantIds: string[];
    position: string | null;
    limit: number;
}) => {
    if (position) {
        return (
            firestore
                .collection(COLLECTIONS.FUNNELS)
                .where("listView", "==", FunnelListView.ContactedList)
                .where("userMeta.position", "==", position)
                .where("restaurantId", "in", restaurantIds)
                .orderBy("updateTime", "desc")
                // .startAfter(lastDoc)
                .limit(limit)
        );
    }
    return (
        firestore
            .collection(COLLECTIONS.FUNNELS)
            .where("listView", "==", FunnelListView.ContactedList)
            .where("restaurantId", "in", restaurantIds)
            .orderBy("updateTime", "desc")
            // .startAfter(lastDoc)
            .limit(limit)
    );
};

export const getRejectedlist = ({
    restaurantIds,
    position,
    limit,
}: {
    restaurantIds: string[];
    position: string | null;
    limit: number;
}) => {
    if (position) {
        return (
            firestore
                .collection(COLLECTIONS.FUNNELS)
                .where("listView", "==", FunnelListView.RejectedList)
                .where("userMeta.position", "==", position)
                .where("restaurantId", "in", restaurantIds)
                .orderBy("updateTime", "desc")
                // .startAfter(lastDoc)
                .limit(limit)
        );
    }
    return (
        firestore
            .collection(COLLECTIONS.FUNNELS)
            .where("listView", "==", FunnelListView.RejectedList)
            .where("restaurantId", "in", restaurantIds)
            .orderBy("updateTime", "desc")
            // .startAfter(lastDoc)
            .limit(limit)
    );
};
export const getHiredlist = ({
    restaurantIds,
    position,
    limit,
}: {
    restaurantIds: string[];
    position: string | null;
    limit: number;
}) => {
    if (position) {
        return (
            firestore
                .collection(COLLECTIONS.FUNNELS)
                .where("listView", "==", FunnelListView.HiredList)
                .where("userMeta.position", "==", position)
                .where("restaurantId", "in", restaurantIds)
                .orderBy("updateTime", "desc")
                // .startAfter(lastDoc)
                .limit(limit)
        );
    }
    return (
        firestore
            .collection(COLLECTIONS.FUNNELS)
            .where("listView", "==", FunnelListView.HiredList)
            .where("restaurantId", "in", restaurantIds)
            .orderBy("updateTime", "desc")
            // .startAfter(lastDoc)
            .limit(limit)
    );
};

export const updateFunnelMetaPlanday = (id: string, data: { departmentId: number }) =>
    firestore
        .collection(COLLECTIONS.FUNNELS)
        .doc(id)
        .set({ plandayMetaData: data }, { merge: true });
