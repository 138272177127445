import { useEffect, useState } from "react";
import { isApplicantTranferPending } from "./api";

export default function useIsApplicantTransferPending(funnelId?: string) {
    const [isPending, setIsPending] = useState(false);
    useEffect(() => {
        let unsubscribe: null | Function = null;
        if (funnelId) {
            unsubscribe = isApplicantTranferPending({ funnelId }).onSnapshot(
                query => {
                    setIsPending(!query.empty);
                },
                err => {
                    console.log(err);
                },
            );
        }

        // returning the unsubscribe function will ensure that
        // we unsubscribe from document changes when our id
        // changes to a different value.
        return () => unsubscribe?.();
    }, [funnelId]);
    return { isPendingTransfer: isPending };
}
