import { useEffect, useMemo } from "react";
import * as FunnelAPI from "../../../features/funnels/api";
import { setHiredlist } from "../../../features/funnels/reducer";
import { useAppSelector, useAppDispatch } from "../../../store/store";
import { selectRestaurantIdsToQuery } from "../../../features/restaurants/reducer";
import { selectFocusedPosition } from "../../../features/userPositions/reducer";
import { setHiredPagination } from "../../../features/pagination/reducer";
import { funnelMapper } from "../../../features/funnels/mapper";

export function useHiredlist() {
    const restaurantIds = useAppSelector(selectRestaurantIdsToQuery);
    const position = useAppSelector(selectFocusedPosition);
    const dispatch = useAppDispatch();
    const funnelIds = useAppSelector(state => state.funnels.contactedlistIds);
    const limit = useAppSelector(state => state.pagination.contactedPagination.limit);
    const rIds = useMemo(() => restaurantIds.filter((_, i) => i < 9), [restaurantIds]);
    useEffect(() => {
        let unsubscribe: () => void | undefined;
        if (rIds?.length) {
            dispatch(setHiredPagination({ isLoading: true }));
            unsubscribe = FunnelAPI.getHiredlist({
                restaurantIds: rIds,
                position,
                limit,
            }).onSnapshot(
                query => {
                    const funnels = query.docs.map(doc =>
                        funnelMapper({
                            id: doc.id,
                            ...doc.data(),
                        } as Contracts.DBFunnelShape),
                    );
                    dispatch(
                        setHiredPagination({
                            isLoading: false,
                            // TODO: figure a better way than this
                            isEndReached:
                                limit > funnels?.length ||
                                funnelIds?.length >= funnels?.length,
                        }),
                    );
                    dispatch(setHiredlist({ funnels }));
                },
                err => {
                    // TODO: add error logging with sentry
                    console.error(err);
                },
            );
        }

        return () => unsubscribe?.();
    }, [rIds, position, limit]);
}
