import { firestore } from "../../firebase/configure";
import { COLLECTIONS } from "../../constants/enums";

export enum ApplicantTransferState {
    FAILED = "failed",
    TRANSFERRED = "transferred",
    PENDING = "pending",
}

export const transferApplicantToAnotherRestaurant = ({
    funnelId,
    applicantId,
    newRestaurantId,
    prevRestaurantId,
}: {
    funnelId: string;
    applicantId: string;
    newRestaurantId: string;
    prevRestaurantId: string;
}) =>
    firestore.collection(COLLECTIONS.APPLICANT_TRANSFER).add({
        createTime: new Date(),
        updateTime: new Date(),
        state: ApplicantTransferState.PENDING,
        funnelId,
        applicantId,
        newRestaurantId,
        prevRestaurantId,
    });

export const isApplicantTranferPending = ({ funnelId }) =>
    firestore
        .collection(COLLECTIONS.APPLICANT_TRANSFER)
        .where("funnelId", "==", funnelId)
        .where("state", "==", ApplicantTransferState.PENDING)
        .orderBy("createTime", "desc")
        .limit(1);
