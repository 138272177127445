import React from "react";

import { InfoSection, WorkExperienceSection } from "./ProfileSections";
import { ProfileBodyContainer } from "./styled";
import { useApplication } from "../../../../features/applications/useApplication";
import { useAppSelector } from "../../../../store/store";
import { shortLocalDateFormat } from "../../../../utils/dates";

import { StyleGuide } from "@constants/StyleGuide";

export const ProfileContent = () => {
    const applicantId = useAppSelector(
        state =>
            state.funnels.funnelMap[state.funnels.focusedProfile as string]?.applicantId,
    );

    const application = useAppSelector(
        state => state.applications.applicationMap[applicantId],
    );

    return (
        <ProfileBodyContainer>
            {Boolean(application?.preferredLocations?.labels) && (
                <InfoSection
                    title="Preferred locations"
                    description={application?.preferredLocations?.labels?.join(", ")}
                />
            )}
            <InfoSection title="About" description={application?.selfIntro} />

            <WorkExperienceSection
                workexperience={application?.workExperience}
                workexperiences={application?.workExperiences}
                industryExperience={application?.workExperienceTotal}
                cv={application?.cv}
            />
            <InfoSection title="Motivation" description={application?.motivation} />
            {Boolean(application?.shiftAvailability?.length) && (
                <InfoSection
                    title="Availability"
                    description={application?.shiftAvailability?.join(", ")}
                />
            )}
            {Boolean(application?.currentLocation) && (
                <InfoSection
                    title="Location"
                    description={
                        typeof application?.currentLocation === "object"
                            ? application?.currentLocation.label
                            : application?.currentLocation
                    }
                />
            )}
            {Boolean(application?.residenceCountry) && (
                <InfoSection
                    title="Currently living in"
                    description={application?.residenceCountry}
                />
            )}
            {Boolean(application?.wineKnowledge) && (
                <InfoSection
                    title="Wine knowledge"
                    description={application?.wineKnowledge}
                />
            )}
            <InfoSection
                title="Languages"
                description={application?.spokenLanguages?.join(", ")}
            />
            <InfoSection
                title="Applied on"
                description={shortLocalDateFormat(application?.createTime)}
            />

            <InfoSection title="Comments" description={application?.comments} />

            {Boolean(application?.hasCpr) && (
                <InfoSection title="Has a CPR number" description={application?.hasCpr} />
            )}

            {/**TODO: pool related */}
            {/* <InfoSection title="Added to pool" description="" />
            <InfoSection title="Applied to" description="" />
            <InfoSection title="Looking for a job within" description="" />
            <InfoSection title="Rejected by" description="" /> */}

            <InfoSection
                description={
                    <span style={{ color: StyleGuide.palette.coal }}>
                        {application?.email}
                    </span>
                }
            />
        </ProfileBodyContainer>
    );
};

export default ProfileContent;
