import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { RequestState } from "@constants/enums";
import { firestore } from "../../firebase/configure";
import { setFunnelSearchKey, setFunnelSearchKeyState } from "../user/reducer";
import API from "../../api/client";

/**
 * Responsible for fetching and updating search keys
 */

export interface Key {
    expireTime: string;
    key: string;
    state: "invalid" | "valid";
}
let attempts = 0;

function getSearchKeyWithValidity(key?: Key) {
    if (!key) {
        return undefined;
    }
    return {
        ...key,
        state:
            !key || new Date(key.expireTime).getTime() < Date.now() ? "invalid" : "valid",
    };
}

export default function useSearchKeys() {
    const dispatch = useAppDispatch();
    // if key is is valid
    const isSearchKeyValid = useAppSelector(state => {
        return state.user.funnelSearchKey?.state === "valid";
    });
    const isSearchKeyLoading = useAppSelector(
        state =>
            state.user.funnelSearchKeyState === RequestState.IDLE ||
            state.user.funnelSearchKeyState === RequestState.LOADING ||
            // for now this to prevent errors in UX
            state.user.funnelSearchKeyState === RequestState.ERROR,
    );
    // @ts-ignore
    const userId = useAppSelector(state => state.user.uid);
    useEffect(() => {
        let unsubscribe: any = null;
        if (!userId) {
            return;
        }
        unsubscribe = firestore
            .collection(`users`)
            .doc(userId)
            .collection("searchKeys")
            .doc("funnelSearchKey")
            .onSnapshot(
                snap => {
                    const data = snap?.data();
                    // @ts-ignore
                    dispatch(setFunnelSearchKey(getSearchKeyWithValidity(data)));
                },
                error => {
                    dispatch(setFunnelSearchKeyState(RequestState.ERROR));
                    console.error(error);
                },
            );

        return () => {
            unsubscribe?.();
        };
    }, [userId]);
    /**
     * Try to get new key if key is invalid and attempts is less than 3
     */
    useEffect(() => {
        // if key is invalid and attempts is less than 3
        // get new key
        if (isSearchKeyLoading) return;
        if (!isSearchKeyValid && attempts < 3) {
            getNewKey();
        }
    }, [isSearchKeyValid, isSearchKeyLoading]);
    /**
     * Get new key from api
     */
    const getNewKey = async () => {
        try {
            dispatch(setFunnelSearchKeyState(RequestState.LOADING));
            attempts = attempts + 1;
            const { isSuccess } = await API.funnelSearchKey({ uid: userId });

            // if request is success reset attempts
            if (isSuccess) {
                attempts = 0;
            }
        } catch (error) {
            console.error(error);
            dispatch(setFunnelSearchKeyState(RequestState.ERROR));
        }
    };
}
