import React from "react";

import { ReactComponent as StarFilledIcon } from "@assets/svg/star_filled.svg";
import MoveAction from "./MoveAction";
import CompletedAction from "./CompletedAction";
import { SendMessageButton } from "./CtaButton";
import CustomRejectPopup from "./CustomRejectPopup";
import PlandayPopup from "./Planday/PlandayPopup";
import API from "../../../../api/client";
import {
    ApplicationState,
    AllowedManualApplicationStateType,
    RejectReason,
} from "@anna/shared";
import { useAppSelector } from "../../../../store/store";
import {
    ApplicantActionsContainer,
    Title,
    Description,
    ActionsSeparator,
    LongArrowIcon,
    PlusIcon,
    StarIcon,
    CtaContainer,
} from "./styled";
import TransferPopup from "@components/CustomModals/ApplicantProfileModal/ApplicantActions/Transfer";

function displayContactedState(state: ApplicationState) {
    switch (state) {
        case ApplicationState.INTERESTED:
        case ApplicationState.CONTACTED_ACCEPTED:
        case ApplicationState.INTERVIEW_PENDING:
        case ApplicationState.INTERVIEW_SCHEDULED:
        case ApplicationState.INTERVIEW_CANCELED:
        case ApplicationState.INTERVIEWED:
        case ApplicationState.TRIAL_PENDING:
        case ApplicationState.TRIAL_SCHEDULED:
        case ApplicationState.TRIAL_CANCELED:
        case ApplicationState.TRIALED:
            return false;

        default:
            return true;
    }
}

const ApplicantActions = ({ openApplicantChat }) => {
    const userId = useAppSelector(state => state.user.uid);
    const funnelId = useAppSelector(state => state.funnels.focusedProfile) as string;
    const state = useAppSelector(state => state.funnels.funnelMap[funnelId]?.state);
    const rejectReason = useAppSelector(
        state => state.funnels.funnelMap[funnelId]?.reasonRejected,
    );
    const moveApplication = async (state: AllowedManualApplicationStateType) => {
        try {
            await API.manualStateChange({ userId, funnelId, state });
        } catch (error) {
            console.error(error);
        }
    };

    const favoriteApplicant = async () => {
        try {
            await API.review({ userId, funnelId });
        } catch (error) {
            console.error(error);
        }
    };

    const undoFavorite = async () => {
        try {
            await API.undoReview({ userId, funnelId });
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <ApplicantActionsContainer>
            <Title>Move applicant</Title>

            <Description>Moving does not send a message</Description>

            {[ApplicationState.APPLIED, ApplicationState.REJECTED].includes(state) && (
                <MoveAction
                    title="Move to Favorites"
                    Icon={StarIcon}
                    onClick={favoriteApplicant}
                />
            )}

            {state === ApplicationState.REVIEWED && (
                <MoveAction
                    title="Moved to Favorites!"
                    label="Undo"
                    Icon={StarFilledIcon}
                    onClick={undoFavorite}
                />
            )}

            {displayContactedState(state) && (
                <MoveAction
                    title="Move to Contacted"
                    Icon={LongArrowIcon}
                    onClick={() => moveApplication(ApplicationState.INTERESTED)}
                />
            )}

            {/* TODO: Activate "Move to team" CTA when we create the Team tab  */}
            {/* {state !== ApplicationState.HIRED && (
                <MoveAction
                    title="Move to Team"
                    Icon={LongArrowIcon}
                    onClick={() => moveApplication(ApplicationState.HIRED)}
                />
            )} */}

            {state !== ApplicationState.REJECTED && (
                <MoveAction
                    title="Move to Rejected"
                    Icon={LongArrowIcon}
                    onClick={() => moveApplication(ApplicationState.REJECTED)}
                />
            )}

            {/* <CompletedAction title="Moved to Contacted!" Icon={LongArrowIcon} /> */}

            <ActionsSeparator />

            <PlandayPopup />

            <TransferPopup />

            <CtaContainer>
                <CustomRejectPopup />

                <SendMessageButton onClick={openApplicantChat} />
            </CtaContainer>
        </ApplicantActionsContainer>
    );
};

export default ApplicantActions;
