import keyBy from "lodash.keyby";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../store/store";
import uniq from "lodash.uniq";
import { MappedWorkHours, Gender } from "@anna/shared";
export interface ApplicationState {
    createTime: Date;
    cv: string;
    email: string;
    eventType: string;
    firstName: string;
    gender: Gender;
    id: string;
    internDuration: string | null;
    isAdministrative: boolean;
    isBackOfHouse: boolean;
    isFrontOfHouse: boolean;
    lastName: string;
    motivation: string;
    phoneNumber: string;
    hasCpr: boolean | null;
    position: Contracts.ApplicationTypeValue;
    positionDetail: string;
    positionAdmin: string;
    positionBOH: string;
    positionFOH: string;
    isIntern: string | null;
    profilePicture: string;
    restaurantId: string;
    restaurantTitle: string | null;
    selfIntro: string;
    visaSectionType: string;
    visaWorkHours: string;
    visaWorkHoursNew?: string;
    visaExpirationDate: string;
    visaType: string;
    startDate?: string;
    spokenLanguages?: string[];
    availabilityYear?: string;
    wineKnowledge?: string;
    managementKnowledge?: string;
    shiftAvailability?: string[];
    drinksKnowledge?: string;
    coffeeKnowledge?: string;
    workExperience: string;
    workExperiences?: any[];
    workExperienceTotal: string;
    workHours: MappedWorkHours;
    residenceCountry?: string;
    nationality?: string;
    passportExpiration?: string;
    educationChef?: string;
    hospitalityDiploma?: string;
    applicantGender?: string;
    internshipPeriodFirstPreference?: string;
    internshipPeriodSecondPreference?: string;
    birthdate?: string;
    address?: string;
    u18Drivercheck?: string[];
    currentLocation?: string | { label: string };
    comments?: string;
    preferredLocations?: { labels: string[] };
}
interface Funnels {
    applicationIds: string[];
    applicationMap: { [id: string]: ApplicationState };
}

// Define the initial state using that type
const initialState: Funnels = {
    applicationIds: [],
    applicationMap: {},
};

export const applicationsSlice = createSlice({
    name: "applications",
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        setApplications(
            state,
            action: PayloadAction<
                { applications: ApplicationState[] } & { append?: boolean }
            >,
        ) {
            const applicationsMapped = action.payload.applications;
            const funnelIds = applicationsMapped.map(f => f.id);
            return {
                ...state,
                applicationIds: action.payload.append
                    ? uniq([...state.applicationIds, ...funnelIds])
                    : funnelIds,
                applicationMap: {
                    ...state.applicationMap,
                    ...keyBy(applicationsMapped, "id"),
                },
            };
        },
    },
});

export const { setApplications } = applicationsSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectApplicants = (state: RootState) => state.funnels.applicantIds;

export default applicationsSlice.reducer;
