import React from "react";
import ApplicantsList from "../../../components/ApplicantsList/index";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { selectHiredlist } from "../../../features/funnels/reducer";
import { useHiredlist } from "./useHiredlist";
import { selectHiredlistCount } from "../../../features/counts/reducer";
import { setHiredPagination } from "../../../features/pagination/reducer";
import { hasFiltersSelected } from "../../../features/filters/homeFilterReducer";
import useHiredlistWithFilters from "./useHiredlistWithFilters";
import useHiredlistCount from "../../../features/counts/useHiredlistCount";

export default function HiredlistContainer() {
    useHiredlistCount();

    const hiredlistIds = useAppSelector(selectHiredlist);
    const count = useAppSelector(selectHiredlistCount);
    const isFiltersUsed = useAppSelector(hasFiltersSelected);

    if (isFiltersUsed) {
        return <ElasticList count={count} hiredlistIds={hiredlistIds} />;
    }
    return <FirebaseList count={count} hiredlistIds={hiredlistIds} />;
}

function FirebaseList({ hiredlistIds, count }) {
    useHiredlist();

    const dispatch = useAppDispatch();

    const limit = useAppSelector(state => state.pagination.hiredPagination.limit);
    const isLoading = useAppSelector(state => state.pagination.hiredPagination.isLoading);
    const isEndReached = useAppSelector(
        state => state.pagination.hiredPagination.isEndReached,
    );

    const handleLoadMore = () => {
        if (isLoading || isEndReached) return;
        dispatch(setHiredPagination({ limit: limit + 10 }));
    };
    return (
        <ApplicantsList
            key="hiredList"
            title="Hired"
            applicantIds={hiredlistIds}
            isOpenedByDefault
            handleLoadMore={handleLoadMore}
            count={count}
            colorTheme={"#4184D3"}
        />
    );
}

function ElasticList({ count, hiredlistIds }) {
    useHiredlistWithFilters();
    const dispatch = useAppDispatch();
    const pagination = useAppSelector(state => state.pagination.hiredPagination);
    const noMoreDataToLoad = pagination?.totalPages <= pagination.page;

    const handleLoadMore = () => {
        if (noMoreDataToLoad) return;
        dispatch(setHiredPagination({ page: pagination.page + 1 }));
    };
    return (
        <ApplicantsList
            key="hiredListElastic"
            title="Hired"
            applicantIds={hiredlistIds}
            isOpenedByDefault
            handleLoadMore={handleLoadMore}
            count={count}
            colorTheme={"#4184D3"}
        />
    );
}
