import React, { useState, useRef } from "react";

import TemplatesToolbar from "../TemplatesToolbar";
import TemplateInput from "./TemplateInput";
import ChatView from "./ChatView";
import { TemplatesListContainer } from "./styled";
import { TemplateType, RejectReason, TemplateVariables } from "@anna/shared";
import { useAppSelector } from "../../../store/store";
import API from "../../../api/client";
import { getFirstName } from "../../../helpers/names";

const Chat = ({ isModalChat = false, BackToProfileButton = <div /> }) => {
    const [activeTemplate, setActiveTemplate] = useState<TemplateType | "">("");
    const [templateText, setTemplateText] = useState("");
    const [defaultTemplateText, setDefaultTemplateText] = useState("");
    const [templateVariables, setTemplateVariables] = useState<
        {
            variable: TemplateVariables;
            customStyle?: string;
            text: string;
            onPress?: () => void;
        }[]
    >([]);

    const customTemplates = useAppSelector(state => state.templates.templateMap);
    const funnelId = useAppSelector(state => state.funnels.focusedChat) as string;
    const funnel = useAppSelector(state => state.funnels.funnelMap[funnelId]);
    const user = useAppSelector(state => state.user);

    const onTemplateSendRef = useRef<() => void>();

    const getTemplateDefaultText = (template, defaultText) => {
        return (
            customTemplates[template]
                ?.split(TemplateVariables.APPLICANT_NAME)
                ?.join(funnel.userMetaFirstName)
                ?.split(TemplateVariables.SENDER_NAME)
                ?.join(getFirstName(user.name || "")) || defaultText
        );
    };

    const onTemplateSelect = (template: TemplateType, defaultText: string) => {
        setActiveTemplate(template);
        setDefaultTemplateText(defaultText);
        setTemplateVariables([]);
        setTemplateText(getTemplateDefaultText(template, defaultText));
    };

    const closeTemplate = () => {
        setActiveTemplate("");
        setTemplateText("");
        setDefaultTemplateText("");
    };

    const resetTemplate = () => {
        setTemplateText(getTemplateDefaultText(activeTemplate, defaultTemplateText));
    };

    const sendNewMessage = async (text: string) => {
        await API.sendChatMessage({
            funnelId,
            message: text,
            // TODO: FIX THIS WEIRD THING should not be needed
            authorMetadata: {
                uid: user.uid,
                displayName: user.name || "",
                image: user.profileImage || "",
            },
            clientNonce: Date.now(),
        }); // current userId});
    };

    const sendTemplate = async (templateValues = {}) => {
        if (templateText.trim() && activeTemplate) {
            switch (activeTemplate) {
                case TemplateType.INTRO:
                    await API.intro({ funnelId, message: templateText });
                    break;
                case TemplateType.INTERVIEW_INVITE:
                    const interviewTemplateValues = { ...templateValues } as {
                        customTimeSlots: Contracts.CustomTimeSlotsDTO;
                        locationId: string;
                    };
                    await API.inviteForInterview({
                        funnelId,
                        userId: user.uid,
                        customMessage: templateText,
                        ...interviewTemplateValues,
                    });
                    break;
                case TemplateType.TRIAL_INVITE_WO_FILE:
                    const trialTemplateValues = { ...templateValues } as {
                        customPreference: Contracts.CustomPreferenceDTO;
                        locationId: string;
                    };
                    await API.inviteForTrial({
                        funnelId,
                        userId: user.uid,
                        customMessage: templateText,
                        ...trialTemplateValues,
                    });
                    break;
                case TemplateType.HIRED:
                    await API.hire({
                        funnelId,
                        userId: user.uid,
                        customMessage: templateText,
                    });
                    break;
                case TemplateType.REJECTION:
                    await API.reject({
                        funnelId,
                        customMessage: templateText,
                        rejectionReason: RejectReason.NO_FIT,
                    });
                    break;

                default:
                    break;
            }
            closeTemplate();
        }
    };

    const renderTemplateInput = ({ scrollBottomRef }) => {
        return (
            <div>
                <TemplateInput
                    sendNewMessage={() => onTemplateSendRef?.current?.()}
                    scrollBottomRef={scrollBottomRef}
                    message={templateText}
                    setMessage={setTemplateText}
                    hideSendButton
                    templateVariables={templateVariables}
                />
            </div>
        );
    };

    return (
        <>
            <ChatView
                sendNewMessage={sendNewMessage}
                renderTemplateInput={activeTemplate && renderTemplateInput}
                isModalChat={isModalChat}
            />

            <TemplatesListContainer isModalChat={isModalChat}>
                <TemplatesToolbar
                    isModalChat={isModalChat}
                    activeTemplate={activeTemplate}
                    onTemplateSelect={onTemplateSelect}
                    closeTemplate={closeTemplate}
                    sendTemplate={sendTemplate}
                    onTemplateSendRef={onTemplateSendRef}
                    BackToProfileButton={BackToProfileButton}
                    setTemplateVariables={setTemplateVariables}
                    templateText={templateText}
                    resetTemplate={resetTemplate}
                />
            </TemplatesListContainer>
        </>
    );
};

export default Chat;
