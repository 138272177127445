import keyBy from "lodash.keyby";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../store/store";
import uniq from "lodash.uniq";
import { WorkHourFilter } from "@anna/shared";
export interface FunnelState {
    id: string;
    restaurantId: string;
    createTime: any;
    updateTime: any;
    sortTime: Date;
    applicantId: string;
    interviewerId?: string;
    interviewLocationId?: string;
    trialLocationId?: string;
    userMetaFirstName: string;
    userMetaLastName: string;
    userMetaProfileImage: string;
    userMetaPosition: Contracts.ApplicationTypeValue;
    userMetaPositionDetail: string;
    //TODO: to be deprecated
    userMetaPositionBoh: string;
    userMetaPositionFoh: string;
    userMetaPositionAdmin: string;
    //
    userMetaWorkHours: string;
    userMetaVisaType: Contracts.VisaType;
    isBackOfHouse: boolean;
    isIntern: boolean | null;
    userMetaPositionInternDuration: string | null;
    state: Contracts.ApplicationState;
    previousState: Contracts.ApplicationState | null;
    favourite?: boolean;
    seen?: boolean; // Restaurant level
    reasonRejected: Contracts.RejectReason;
    plandayMetaDataDepartmentId?: number | null;
    plandayMetaDataEmployeeId?: string | null;
    subscribers?: string[];
    firstSubscriber?: Contracts.DBFunnelShape["firstSubscriber"];
    lastActivity?: Contracts.DBFunnelShape["lastActivity"];
    hasApplicantContacted?: boolean;
    isOriginSharedPool?: boolean;
    restaurantCategory?: Contracts.RestaurantCategory;
    addedToPool?: any;
    expireTime?: any; // Expiry time in the shared talent pool
    requestTime?: any; // Time the user requested an introduction in the shared talent pool
    workhourFilter: WorkHourFilter[];
}
interface Funnels {
    applicantIds: string[];
    shortlistIds: string[];
    contactedlistIds: string[];
    rejectedlistIds: string[];
    hiredlistIds: string[];
    yourInboxIds: string[];
    teamInboxIds: string[];
    otherInboxIds: string[];
    funnelMap: { [id: string]: FunnelState };
    focusedChat: string | null;
    focusedProfile: string | null;
    profileSwipeIds: string[];
    searchIds: string[];
}

// Define the initial state using that type
const initialState: Funnels = {
    applicantIds: [],
    shortlistIds: [],
    contactedlistIds: [],
    rejectedlistIds: [],
    hiredlistIds: [],
    yourInboxIds: [],
    teamInboxIds: [],
    otherInboxIds: [],
    funnelMap: {},
    focusedChat: null,
    focusedProfile: null,
    profileSwipeIds: [],
    searchIds: [],
};

export const funnelsSlice = createSlice({
    name: "funnels",
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        setApplicants(
            state,
            action: PayloadAction<{ funnels: FunnelState[] } & { append?: boolean }>,
        ) {
            const funnelsMapped = action.payload.funnels;
            const funnelIds = funnelsMapped.map(f => f.id);
            return {
                ...state,
                applicantIds: action.payload.append
                    ? uniq([...state.applicantIds, ...funnelIds])
                    : funnelIds,
                funnelMap: {
                    ...state.funnelMap,
                    ...keyBy(funnelsMapped, "id"),
                },
            };
        },
        setShortlist(
            state,
            action: PayloadAction<{ funnels: FunnelState[] } & { append?: boolean }>,
        ) {
            const funnelsMapped = action.payload.funnels;
            const funnelIds = funnelsMapped.map(f => f.id);
            return {
                ...state,
                shortlistIds: action.payload.append
                    ? uniq([...state.shortlistIds, ...funnelIds])
                    : funnelIds,
                funnelMap: {
                    ...state.funnelMap,
                    ...keyBy(funnelsMapped, "id"),
                },
            };
        },
        setContactedlist(
            state,
            action: PayloadAction<{ funnels: FunnelState[] } & { append?: boolean }>,
        ) {
            const funnelsMapped = action.payload.funnels;
            const funnelIds = funnelsMapped.map(f => f.id);
            return {
                ...state,
                contactedlistIds: action.payload.append
                    ? uniq([...state.contactedlistIds, ...funnelIds])
                    : funnelIds,
                funnelMap: {
                    ...state.funnelMap,
                    ...keyBy(funnelsMapped, "id"),
                },
            };
        },
        setRejectedlist(
            state,
            action: PayloadAction<{ funnels: FunnelState[] } & { append?: boolean }>,
        ) {
            const funnelsMapped = action.payload.funnels;
            const funnelIds = funnelsMapped.map(f => f.id);
            return {
                ...state,
                rejectedlistIds: action.payload.append
                    ? uniq([...state.rejectedlistIds, ...funnelIds])
                    : funnelIds,
                funnelMap: {
                    ...state.funnelMap,
                    ...keyBy(funnelsMapped, "id"),
                },
            };
        },
        setHiredlist(
            state,
            action: PayloadAction<{ funnels: FunnelState[] } & { append?: boolean }>,
        ) {
            const funnelsMapped = action.payload.funnels;
            const funnelIds = funnelsMapped.map(f => f.id);
            return {
                ...state,
                hiredlistIds: action.payload.append
                    ? uniq([...state.hiredlistIds, ...funnelIds])
                    : funnelIds,
                funnelMap: {
                    ...state.funnelMap,
                    ...keyBy(funnelsMapped, "id"),
                },
            };
        },
        setYourInbox(
            state,
            action: PayloadAction<{ funnels: FunnelState[] } & { append: boolean }>,
        ) {
            const funnelsMapped = action.payload.funnels;
            const funnelIds = funnelsMapped.map(f => f.id);
            return {
                ...state,
                yourInboxIds: action.payload.append
                    ? uniq([...state.yourInboxIds, ...funnelIds])
                    : funnelIds,
                funnelMap: {
                    ...state.funnelMap,
                    ...keyBy(funnelsMapped, "id"),
                },
                // if no focused chat selected set it to first chat in your inbox
                focusedChat: state.focusedChat || funnelsMapped?.[0].id,
            };
        },
        setTeamInbox(
            state,
            action: PayloadAction<{ funnels: FunnelState[] } & { append: boolean }>,
        ) {
            const funnelsMapped = action.payload.funnels;
            const funnelIds = funnelsMapped.map(f => f.id);
            return {
                ...state,
                teamInboxIds: action.payload.append
                    ? uniq([...state.teamInboxIds, ...funnelIds])
                    : funnelIds,
                funnelMap: {
                    ...state.funnelMap,
                    ...keyBy(funnelsMapped, "id"),
                },
            };
        },
        setOtherInbox(
            state,
            action: PayloadAction<{ funnels: FunnelState[] } & { append: boolean }>,
        ) {
            const funnelsMapped = action.payload.funnels;
            const funnelIds = funnelsMapped.map(f => f.id);
            return {
                ...state,
                otherInboxIds: action.payload.append
                    ? uniq([...state.otherInboxIds, ...funnelIds])
                    : funnelIds,
                funnelMap: {
                    ...state.funnelMap,
                    ...keyBy(funnelsMapped, "id"),
                },
            };
        },

        resetLists(state) {
            return {
                ...state,
                applicantIds: [],
                shortlistIds: [],
                contactedlistIds: [],
                rejectedlistIds: [],
                hiredlistIds: [],
                yourInboxIds: [],
                teamInboxIds: [],
                otherInboxIds: [],
            };
        },

        setFocusedChat(state, action: PayloadAction<string | null>) {
            return {
                ...state,
                focusedChat: action.payload,
            };
        },
        setFocusedProfile(
            state,
            action: PayloadAction<{ funnelId: string | null; funnelIds?: string[] }>,
        ) {
            return {
                ...state,
                focusedProfile: action.payload.funnelId,
                profileSwipeIds: action.payload.funnelIds || state.profileSwipeIds,
            };
        },
        setSearchResults(
            state,
            action: PayloadAction<{ funnels: FunnelState[] } & { append?: boolean }>,
        ) {
            const funnelsMapped = action.payload.funnels;
            const funnelIds = funnelsMapped.map(f => f.id);
            return {
                ...state,
                searchIds: action.payload.append
                    ? uniq([...state.searchIds, ...funnelIds])
                    : funnelIds,
                funnelMap: {
                    ...state.funnelMap,
                    ...keyBy(funnelsMapped, "id"),
                },
            };
        },
    },
});

export const {
    setApplicants,
    setShortlist,
    setContactedlist,
    setRejectedlist,
    setHiredlist,
    setYourInbox,
    setTeamInbox,
    setOtherInbox,
    //
    resetLists,
    //
    setFocusedChat,
    setFocusedProfile,
    //
    setSearchResults,
} = funnelsSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectApplicants = (state: RootState) => state.funnels.applicantIds;
export const selectShortlist = (state: RootState) => state.funnels.shortlistIds;
export const selectContactedlist = (state: RootState) => state.funnels.contactedlistIds;
export const selectRejectedlist = (state: RootState) => state.funnels.rejectedlistIds;
export const selectHiredlist = (state: RootState) => state.funnels.hiredlistIds;
export const selectYourInbox = (state: RootState) => state.funnels.yourInboxIds;
export const selectYourInboxLatest = (state: RootState) =>
    state.funnels.yourInboxIds?.[0];
export const selectTeamInbox = (state: RootState) => state.funnels.teamInboxIds;
export const selectOtherInbox = (state: RootState) => state.funnels.otherInboxIds;
// export const selectFunnel = (state: RootState) => state.funnels.funnelMap[];
export const selectFocusedChat = (state: RootState) => state.funnels.focusedChat;

export const selectSearchResults = (state: RootState) => state.funnels.searchIds;

export function getFunnelPosition(funnel: FunnelState) {
    return `${funnel.userMetaPositionAdmin?.concat(" ") ?? ""}${
        funnel.isBackOfHouse ? funnel.userMetaPositionBoh : funnel.userMetaPositionFoh
    }`;
}
export function selectFunnelMetaDataPlandayDepartmentId(state: RootState) {
    const selectedFunnelId = state.funnels.focusedProfile || state.funnels.focusedChat;
    return selectedFunnelId
        ? state.funnels.funnelMap[selectedFunnelId]?.plandayMetaDataDepartmentId
        : null;
}
export function selectApplicantIdByFunnelId(state: RootState) {
    const selectedFunnelId = state.funnels.focusedProfile || state.funnels.focusedChat;
    return selectedFunnelId
        ? state.funnels.funnelMap[selectedFunnelId]?.applicantId
        : null;
}
export default funnelsSlice.reducer;
