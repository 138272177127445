// TODO: Move to shared
export const COLLECTIONS = {
    RECRUITMENT: "recruitment",
    RESTAURANTS: "restaurants",
    APPLICATIONS: "applications",
    NOTIFICATIONS: "notifications",
    FUNNELS: "funnels",
    ACTIVITY: "activity",
    USERS: "users",
    BOOKINGS: "bookings",
    USER_INTERVIEW_TIME_SLOT_SUGGESTIONS: "interviewSuggestedTimeSlots",
    USER_CALENDAR_DETAILS: "calendars",
    USER_PREFERENCES: "userPreferences",
    USER_ACTIVITY: "userActivity",
    RESTAURANT_TRIAL_TIME_SLOT_SUGGESTIONS: "trialSuggestedTimeSlots",
    RESTAURANT_POSITIONS: "positions",
    RESTAURANT_TRIAL_PREFERENCES: "trialPreferences",
    RESTAURANT_TRIAL_LOCATIONS: "trialLocations",
    RESTAURANT_TEAM_ACTIVITY: "teamActivity",
    INTERVIEW_LOCATIONS: "interviewLocations",
    INTERVIEW_PREFERENCES: "interviewPreferences",
    DEVICES: "devices",
    TEMPLATES: "templates",
    CONTRACTS: "contracts",
    RESTAURANT_INTEGRATIONS: "integrations",
    USER_RECRUITMENT: "userRecruitment",
    ENRICHED_APPLICANT_DATA: "enrichedData",

    SHARED_TALENT_POOL_APPLICATIONS: "sharedPoolApplications",
    SHARED_TALENT_POOL_FAVOURITES: "sharedPoolFavourites",
    SHARED_TALENT_POOL_INTRODUCTIONS: "sharedPoolIntroductions",
    SHARED_TALENT_POOL_REPORTS: "sharedPoolReports",
    SHARED_TALENT_POOL_VIEWED_APPLICATIONS: "viewedSharedPoolApplications",

    JOBS: "jobs",
    APPLICANT_TRANSFER: "applicantTransfer",
};

// All trial booking types must start with the following prefix
export const TrialBookingPrefix = "TRIAL";

export enum RequestState {
    IDLE = "idle",
    LOADING = "loading",
    SUCCESS = "success",
    ERROR = "error",
}
