import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../store/store";
// import { funnelMapper, funnelElasticMapper } from "./mapper";
export interface Pagination {
    isLoading: boolean;
    // firebase
    limit: number;
    isEndReached: boolean;
    // elastic
    page: number;
    current: number;
    size: number;
    totalPages: number;
    totalResults: number;
}
interface PaginationState {
    applicantPagination: Pagination;
    shortlistPagination: Pagination;
    contactedPagination: Pagination;
    rejectedPagination: Pagination;
    hiredPagination: Pagination;
    yourInboxPagination: Pagination;
    teamInboxPagination: Pagination;
    otherInboxPagination: Pagination;
}
export const initialPagination = {
    isLoading: false,
    // firebase related
    limit: 10,
    isEndReached: false,
    // elastic related
    page: 1,
    current: 0,
    size: 0,
    totalPages: 0,
    totalResults: 0,
};
// Define the initial state using that type
const initialState: PaginationState = {
    applicantPagination: initialPagination,
    shortlistPagination: initialPagination,
    contactedPagination: initialPagination,
    rejectedPagination: initialPagination,
    hiredPagination: initialPagination,
    yourInboxPagination: initialPagination,
    teamInboxPagination: initialPagination,
    otherInboxPagination: initialPagination,
};

export const paginationSlice = createSlice({
    name: "pagination",
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        setApplicantPagination(state, action: PayloadAction<Partial<Pagination>>) {
            return {
                ...state,
                applicantPagination: {
                    ...state.applicantPagination,
                    ...action.payload,
                },
            };
        },
        setShortlistPagination(state, action: PayloadAction<Partial<Pagination>>) {
            return {
                ...state,
                shortlistPagination: {
                    ...state.shortlistPagination,
                    ...action.payload,
                },
            };
        },
        setContactedPagination(state, action: PayloadAction<Partial<Pagination>>) {
            return {
                ...state,
                contactedPagination: {
                    ...state.contactedPagination,
                    ...action.payload,
                },
            };
        },
        setRejectedPagination(state, action: PayloadAction<Partial<Pagination>>) {
            return {
                ...state,
                rejectedPagination: {
                    ...state.rejectedPagination,
                    ...action.payload,
                },
            };
        },
        setHiredPagination(state, action: PayloadAction<Partial<Pagination>>) {
            return {
                ...state,
                hiredPagination: {
                    ...state.hiredPagination,
                    ...action.payload,
                },
            };
        },
        setYourInboxPagination(state, action: PayloadAction<Partial<Pagination>>) {
            return {
                ...state,
                yourInboxPagination: {
                    ...state.yourInboxPagination,
                    ...action.payload,
                },
            };
        },
        setTeamInboxPagination(state, action: PayloadAction<Partial<Pagination>>) {
            return {
                ...state,
                teamInboxPagination: {
                    ...state.teamInboxPagination,
                    ...action.payload,
                },
            };
        },
        setOtherInboxPagination(state, action: PayloadAction<Partial<Pagination>>) {
            return {
                ...state,
                otherInboxPagination: {
                    ...state.otherInboxPagination,
                    ...action.payload,
                },
            };
        },
        resetPagination(state) {
            return {
                ...state,
                applicantPagination: initialPagination,
                shortlistPagination: initialPagination,
                contactedPagination: initialPagination,
                rejectedPagination: initialPagination,
                hiredPagination: initialPagination,
                yourInboxPagination: initialPagination,
                teamInboxPagination: initialPagination,
                setOtherInboxPagination: initialPagination,
            };
        },
    },
});

export const {
    setApplicantPagination,
    setShortlistPagination,
    setContactedPagination,
    setRejectedPagination,
    setHiredPagination,
    setYourInboxPagination,
    setTeamInboxPagination,
    setOtherInboxPagination,
    resetPagination,
} = paginationSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectApplicantsPagination = (state: RootState) =>
    state.pagination.applicantPagination;

export default paginationSlice.reducer;
