import React from "react";

import { useAppSelector } from "../../../../store/store";
import { getHumanReadableStates } from "../../../../helpers/states";
import { shortLocalDateFormat, mediumLocalDateFormat } from "../../../../utils/dates";
import PhoneNumber from "@components/PhoneNumber";
import { VisaType, TypeformVisaType } from "@anna/shared";
import placeholderAvatar from "../../../../assets/images/placeholder_avatar.png";
import {
    ApplicantHeader,
    ApplicantHeaderInfo,
    ApplicantBasicInfo,
    ApplicantProfilePicture,
    ApplicantStatus,
    ApplicantName,
    ApplicantTitle,
    ApplicantDetailRow,
    ApplicantDetailTitle,
    ApplicantDetailDescription,
} from "./styled";

const ProfileHeader = () => {
    const funnel = useAppSelector(
        state => state.funnels.funnelMap[state.funnels.focusedProfile as string],
    );
    const startDate = useAppSelector(
        state => state.applications.applicationMap[funnel.applicantId]?.startDate,
    );
    return (
        <ApplicantHeader>
            <ApplicantHeaderInfo>
                <ApplicantBasicInfo>
                    <ApplicantProfilePicture
                        src={funnel?.userMetaProfileImage || placeholderAvatar}
                    />

                    <div>
                        <ApplicantStatus>
                            {getHumanReadableStates(
                                funnel?.state,
                                funnel?.reasonRejected,
                            )}
                        </ApplicantStatus>

                        <ApplicantName>
                            {funnel?.userMetaFirstName} {funnel?.userMetaLastName}
                        </ApplicantName>

                        <ApplicantTitle>
                            {funnel?.userMetaPositionDetail || funnel?.userMetaPosition}
                        </ApplicantTitle>
                    </div>
                </ApplicantBasicInfo>

                <PhoneNumber />
            </ApplicantHeaderInfo>

            <ApplicantDetailRow>
                {Boolean(funnel?.createTime) && (
                    <div>
                        <ApplicantDetailTitle>Applied</ApplicantDetailTitle>

                        <ApplicantDetailDescription>
                            {shortLocalDateFormat(funnel?.createTime)}
                        </ApplicantDetailDescription>
                    </div>
                )}

                {Boolean(startDate) && (
                    <div>
                        <ApplicantDetailTitle>Start date</ApplicantDetailTitle>

                        <ApplicantDetailDescription>
                            {/* @ts-ignore */}
                            {mediumLocalDateFormat(startDate)}
                        </ApplicantDetailDescription>
                    </div>
                )}

                {Boolean(funnel?.userMetaWorkHours) && (
                    <div>
                        <ApplicantDetailTitle>Hours</ApplicantDetailTitle>

                        <ApplicantDetailDescription>
                            {funnel?.userMetaWorkHours}
                        </ApplicantDetailDescription>
                    </div>
                )}

                {Boolean(funnel?.userMetaVisaType) && (
                    <div>
                        <ApplicantDetailTitle>Visa</ApplicantDetailTitle>

                        <ApplicantDetailDescription>
                            {funnel?.userMetaVisaType}
                        </ApplicantDetailDescription>
                    </div>
                )}
            </ApplicantDetailRow>
        </ApplicantHeader>
    );
};

export default ProfileHeader;
